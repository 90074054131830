
import ApiService from "@/core/services/api.service";


export const SET_LISTA_ARQUIVO = "setListArquivoDocRev";
export const SET_LISTA_ARQUIVO_REV = "setListArquivoRev";
export const SET_ARQUIVO = "setListArquivoRev";
export const SET_ROTA = "setRota"




const actions = {
    //arquivo
    async create_arquivo(context, value) {

        await ApiService.post('arquivo', value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_arquivo(context, value) {

        await ApiService.post('arquivo/update',value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_arquivos(context) {
        await ApiService.get('arquivo')
            .then(response => {
                context.commit(SET_LISTA_ARQUIVO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async set_arquivo(context,value) {
        await ApiService.get('arquivo/'+value)
            .then(response => {
                context.commit(SET_ARQUIVO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async delete_arquivo(context, value) {


        await ApiService.delete("arquivo/" + value)
          .then((response) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
        context.commit(SET_LISTA_ARQUIVO, value)
        context.commit(SET_LISTA_ARQUIVO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_ARQUIVO](state, value) {
        state.lista_arquivos = value;
    },
    [SET_LISTA_ARQUIVO_REV](state, value) {
        state.lista_arquivos = state.lista_arquivos.filter(
            (arquivos) => arquivos.id !== value.id
        );
    },
    [SET_ARQUIVO](state, value) {
        state.arquivo = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
  

    SET_MESSAGE_ALERT(state, value) {
        let customMessage;
        if (typeof value.message === "object") {
            let item = Object.entries(value.message)[0];
            customMessage = `${item[0]}: ${item[1][0]}`;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_arquivos: [],
    lista_arquivos_doc: [],
    arquivo:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
