
import ApiService from "@/core/services/api.service";
import store from "@/core/store/index";


export const SET_LISTA_MOV = "setListmov";
export const SET_LISTA_MOV_NEW = "setListMovNew";
export const SET_LISTA_MOV_APROV = "setListMovAprov";

export const SET_LISTA_MOV_REV = "setListmovRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'

export const SET_CONTA = "setConta"


const actions = {
    //mov
    async create_mov(context, value) {

        let message = await ApiService.post('mov', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_LISTA_MOV_NEW, message.message.conteudo)


    },
    async create_mov_lote(context, value) {

        let message = await ApiService.post('mov/lote', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_LISTA_MOV_NEW, message.message.conteudo)


    },
    async update_mov(context, value) {

        await ApiService.put('mov/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async aprovar_mov(context, value) {


       let message = await ApiService.put('mov/aprove/' + value.id)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))


        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_LISTA_MOV_REV, value)
        context.commit(SET_LISTA_MOV_APROV, message.message.conteudo )
    },


    async listar_movs(context) {
        await ApiService.get('mov')
            .then(response => {
                context.commit(SET_LISTA_MOV, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_mov(context, value) {
        await ApiService.get('mov/' + value.id)
            .then(response => {
                context.commit(SET_LISTA_MOV, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    set_conta(context, item) {
        context.commit(SET_CONTA, item)
    },

    async delete_mov(context, value) {


        await ApiService.delete("mov/" + value)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        // context.commit(SET_LISTA_MOV, value)
        context.commit(SET_LISTA_MOV_REV, value)


    },

    set_rota(context, value) {
        context.commit(SET_ROTA, value)
    }



};
const getters = {};

const mutations = {
    [SET_LISTA_MOV](state, value) {
        state.lista_mov = value;
    },
    [SET_LISTA_MOV_NEW](state, value) {
        state.lista_mov.push(value);
    },

    [SET_LISTA_MOV_APROV](state, value) {

        if (value.tipo == 2) {  
            store.state.contas.conta.saldo = parseFloat(store.state.contas.conta.saldo) + parseFloat(value.valor)
        } else {
            store.state.contas.conta.saldo = parseFloat(store.state.contas.conta.saldo) - parseFloat(value.valor)
        }

        state.lista_mov.push(value);
    },
    [SET_LISTA_MOV_REV](state, value) {
        state.lista_mov = state.lista_mov.filter(
            (movs) => movs.id !== value.id
        );
    },
    [SET_CONTA](state, value) {
        state.conta = value;
    },
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },

};

const state = {
    lista_mov: [],
    conta: {},
    mensagem_alert: "",
    rota: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
